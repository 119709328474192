// @ts-ignore
import curve from '@nrk/yr-catmull-rom-spline';
import { useCallback, useState } from 'react';
import { useId } from 'react';
import { flipCoordinatesVertically } from '../../lib/helpers/graph';
import { getRandomInt } from '../../lib/helpers/math';
import { useDebouncedResize } from '../../lib/hooks/useDebouncedResize';
import { Coordinate, CoordinateWithOptionalY } from '../../model/coordinate';

interface IProps {
  normalizedLineCoordinate: CoordinateWithOptionalY[];
  normalizedZeroY: number;
  phases: {
    type: string;
    normalizedWidth: number;
    normalizedX: number;
  }[];
}

export function SunGraph__Phases(props: IProps) {
  const { normalizedLineCoordinate, normalizedZeroY, phases } = props;

  const maskId = useId();

  const [forceUpdateKey, setForceUpdateKey] = useState<number>(1);

  // We need to flip the coordinates vertically
  // because our coordinates have 0,0 at the bottom left
  // while SVG has 0,0 at the top left.
  const flippedNormalizedLineCoordinate = flipCoordinatesVertically(normalizedLineCoordinate);
  // Need to insert coordinates at the start for the left down corner and
  // coordinates at the end for the right down corner.
  // This is so we can fill the "air" at the bottom of the graph.
  flippedNormalizedLineCoordinate.unshift([0, 1]);
  flippedNormalizedLineCoordinate.push([1, 1]);
  const flippedCoordinatesPath = createCurvePath(flippedNormalizedLineCoordinate as Coordinate[]);

  // We have to force update the mask on resize because of a bug in chrome:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=330815
  const forceUpdate = useCallback(() => {
    setForceUpdateKey(getRandomInt(1000));
  }, []);

  useDebouncedResize(forceUpdate, 250);

  return (
    <svg width="100%" height="100%" key={forceUpdateKey}>
      <mask id={maskId} maskContentUnits="userSpaceOnUse">
        <svg width="100%" height="100%" viewBox="0 0 1 1" preserveAspectRatio="none">
          <rect fill="white" x="0" y="0" width="100%" height="100%" />
          <path fill="black" d={`${flippedCoordinatesPath}`} />
          <rect x="0" y="0" width="100%" height={1 - normalizedZeroY} fill="black" />
        </svg>
      </mask>

      {phases.map(newPhase => {
        if (newPhase.type === 'daylight') {
          return null;
        }
        return (
          <rect
            className="sun-graph__phase"
            data-type={newPhase.type}
            key={newPhase.normalizedX}
            x={`${newPhase.normalizedX * 100}%`}
            y="0"
            width={`${newPhase.normalizedWidth * 100}%`}
            height="100%"
            mask={`url(#${maskId}`}
          />
        );
      })}
    </svg>
  );
}

function createCurvePath(coordinates: CoordinateWithOptionalY[]) {
  const bezierPoints = curve.points(coordinates);
  const path = curve.svgPath(bezierPoints);

  return path;
}
