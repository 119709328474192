import settings from '../../app/settings';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ClickableLink } from '../Clickable/Clickable';
import { InfoPanel } from '../InfoPanel/InfoPanel';
import { Text } from '../Text/Text';
import { Translate } from '../Translate/Translate';

interface IProps {
  openedById: string;
  onClose: () => void;
}

export const SunCard_InfoPanel = (props: IProps) => {
  const { openedById, onClose } = props;

  const translate = useTranslate();

  return (
    <InfoPanel
      testId={'sun-info-panel'}
      openedById={openedById}
      title={translate('sunCard/infoPanel/title')}
      onClose={onClose}
    >
      <Translate id="sunCard/infoPanel/textParagraphs" splitLinebreaksAs="p" />

      <Translate
        id="sunCard/infoPanel/linkDescription-1"
        values={{
          link: (
            <ClickableLink isExternal={true} href={settings.external.sun.twilight.url}>
              <Text size="4" isLink={true}>
                {settings.external.sun.twilight.displayUrl}
              </Text>
            </ClickableLink>
          )
        }}
        splitLinebreaksAs="p"
      />
      <Translate
        id="sunCard/infoPanel/linkDescription-2"
        values={{
          link: (
            <ClickableLink isExternal={true} href={settings.external.sun.solstice.url}>
              <Text size="4" isLink={true}>
                {settings.external.sun.solstice.displayUrl}
              </Text>
            </ClickableLink>
          )
        }}
      />
    </InfoPanel>
  );
};
