// @ts-ignore
import curve from '@nrk/yr-catmull-rom-spline';
import classNames from 'classnames';
import { Fragment, useId } from 'react';
import { Coordinate, CoordinateWithOptionalY } from '../../model/coordinate';
import { pixelValueToRem } from '../../lib/formatGraphics';
import { splitCoordinates } from '../../lib/helpers/graph';
import './GraphLine.scss';

export const GRAPH_LINE_STROKE_WIDTH_BOLD = 2.5;
export const GRAPH_LINE_STROKE_WIDTH_THIN = 1.5;

interface IProps {
  curved?: boolean;
  className?: string;
  testId?: string;
  strokeWidth?: number;
  normalizedCoordinates?: CoordinateWithOptionalY[];
  outlined?: boolean;
  dashed?: boolean;
  /**
   * Debug will draw the actual coordinates as circles
   * to make it easier to see if they are placed correcyly
   */
  debug?: boolean;
  /**
   * Gradients or patterns referred to by the `strokeId` or `fillId` props
   * must be included as children of this element so they will get the correct
   * height in Chrome when the font size is not default.
   * This is because the gradient or pattern with `height="100%""` must be
   * children of this element's innermost svg child in order to get the correct height.
   * See https://nrknyemedier.atlassian.net/browse/YR-5036
   */
  renderStrokeAndFillElement?: (id: string) => React.ReactNode;
}

export function GraphLine(props: IProps) {
  const {
    curved = false,
    className,
    testId,
    strokeWidth = GRAPH_LINE_STROKE_WIDTH_BOLD,
    normalizedCoordinates,
    outlined = false,
    dashed = false,
    debug = false,
    renderStrokeAndFillElement
  } = props;

  const lineStrokeId = useId();
  const circleFillId = useId();

  const lineStroke = renderStrokeAndFillElement != null ? `url(#${lineStrokeId})` : 'currentColor';
  const circleFill = renderStrokeAndFillElement != null ? `url(#${circleFillId})` : 'currentColor';

  if (normalizedCoordinates == null) {
    return null;
  }

  const outlineWidth = strokeWidth + 2;

  const coordinates = normalizedCoordinates.map(normalizedCoordinate => {
    if (normalizedCoordinate == null) {
      return normalizedCoordinate;
    }

    const [normalizedX, normalizedY] = normalizedCoordinate;

    const x = normalizedX;
    const y = normalizedY == null ? normalizedY : 1 - normalizedY;

    return [x, y] as CoordinateWithOptionalY;
  });

  let segments = splitCoordinates(coordinates);

  return (
    <svg
      className={classNames('graph-line', { 'graph-line--dashed': dashed }, className)}
      width="100%"
      height="100%"
      data-testid={testId}
    >
      {/* 
        This SVG element scales horizontally because it has a width of 100% and has a viewBox.
        The height stays the same because "preserveAspectRatio" is set to "none".
      */}
      <svg width="100%" height="100%" viewBox={`0 0 1 1`} preserveAspectRatio="none">
        {segments.map((segment, index) => {
          if (segment.coordinates.length === 2) {
            return (
              <Fragment key={index}>
                {outlined === true && (
                  <line
                    className={`graph-line__line graph-line__line--outline graph-line__line--${segment.type}`}
                    x1={segment.coordinates[0][0]}
                    y1={segment.coordinates[0][1]}
                    x2={segment.coordinates[1][0]}
                    y2={segment.coordinates[1][1]}
                    strokeWidth={pixelValueToRem(outlineWidth)}
                    vectorEffect="non-scaling-stroke"
                  />
                )}

                <line
                  className={`graph-line__line graph-line__line--${segment.type}`}
                  x1={segment.coordinates[0][0]}
                  y1={segment.coordinates[0][1]}
                  x2={segment.coordinates[1][0]}
                  y2={segment.coordinates[1][1]}
                  stroke={segment.type === 'coordinates' ? lineStroke : 'currentColor'}
                  strokeWidth={pixelValueToRem(strokeWidth)}
                  vectorEffect="non-scaling-stroke"
                />
              </Fragment>
            );
          }

          if (segment.coordinates.length > 2) {
            let path = createLinePath(segment.coordinates);

            if (curved === true) {
              const bezierPoints = curve.points(segment.coordinates);
              path = curve.svgPath(bezierPoints);
            }

            return (
              <Fragment key={index}>
                {outlined === true && (
                  <path
                    className="graph-line__path graph-line__path--outline"
                    d={path}
                    fill="none"
                    strokeWidth={pixelValueToRem(outlineWidth)}
                    vectorEffect="non-scaling-stroke"
                  />
                )}

                <path
                  className="graph-line__path"
                  d={path}
                  fill="none"
                  stroke={lineStroke}
                  strokeWidth={pixelValueToRem(strokeWidth)}
                  vectorEffect="non-scaling-stroke"
                />
              </Fragment>
            );
          }

          return null;
        })}

        {debug === true &&
          segments.map(segment => {
            return segment.coordinates.map(coordinate => {
              if (coordinate[1] == null) {
                return null;
              }

              return <circle cx={coordinate[0]} cy={coordinate[1]} r={'2px'} fill={'SpringGreen'} />;
            });
          })}

        {renderStrokeAndFillElement && renderStrokeAndFillElement(lineStrokeId)}
      </svg>
      {renderStrokeAndFillElement && renderStrokeAndFillElement(circleFillId)}

      {/*
        We draw a circle for a point with a y coordinate that has points
        without a y coordinate to its immediate left and right.
        This circle should not scale horizontally so we don't draw it
        within the horizontally scaling SVG we draw the lines within.
      */}
      {segments.map((segment, index) => {
        if (segment.coordinates.length === 1) {
          return (
            <Fragment key={index}>
              {outlined === true && (
                <circle
                  className="graph-line__point graph-line__point--outline"
                  cx={`${segment.coordinates[0][0] * 100}%`}
                  cy={`${segment.coordinates[0][1] * 100}%`}
                  r={pixelValueToRem(outlineWidth + 1)}
                />
              )}

              <circle
                className="graph-line__point"
                cx={`${segment.coordinates[0][0] * 100}%`}
                cy={`${segment.coordinates[0][1] * 100}%`}
                // Make the circle slightly larger than the stroke width to ensure it is visible
                r={pixelValueToRem(strokeWidth + 1)}
                fill={circleFill}
              />
            </Fragment>
          );
        }

        return null;
      })}
    </svg>
  );
}

function createLinePath(coordinates: Coordinate[]) {
  if (coordinates.length < 2) {
    return '';
  }

  let path = `M ${coordinates[0][0]},${coordinates[0][1]}`;

  for (let i = 0; i < coordinates.length; i++) {
    path = `${path} L ${coordinates[i][0]},${coordinates[i][1]}`;
  }

  return path;
}
