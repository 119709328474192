import { createRelativeTimeLabel } from '../../../lib/helpers/time';
import { sentenceCase } from '../../../lib/string';
import { CoordinateWithOptionalY } from '../../../model/coordinate';
import { IElevationMinute } from '../../../model/celestialEvents';
import { ITranslateFunction } from '../../../model/translate';
import { TGraphGridNewHorizontalLine, TGraphGridNewVerticalLine } from '../../GraphGridNew/GraphGridNew';
import { getSunPhaseFromElevation } from '../../SunCard/helpers/data';
import { SUN_ELEVATION_IS_ABOVE_HORIZON } from '../../SunCard/SunCard';

const GRAPH_DEGREES_OVER = 10;
const GRAPH_DEGREES_UNDER = 10;

export function createSunGraphData({
  isFirstRender,
  minutes,
  translate
}: {
  isFirstRender: boolean;
  minutes: IElevationMinute[];
  translate: ITranslateFunction;
}) {
  const verticalLines: TGraphGridNewVerticalLine[] = [];
  const normalizedLineCoordinate: CoordinateWithOptionalY[] = [];

  const ariaLabels: { label: string; index: number }[] = [];

  const phases: { type: string; normalizedWidth: number; normalizedX: number }[] = [];
  let currentPhase: { type: string; normalizedWidth: number; normalizedX: number };

  // The graph is going from GRAPH_DEGREES_OVER over graphMax
  let graphMax = Math.max(...minutes.map(minute => minute.sun.elevation)) + GRAPH_DEGREES_OVER;
  let graphMin = Math.min(...minutes.map(minute => minute.sun.elevation)) - GRAPH_DEGREES_UNDER;
  if (graphMax < 0) graphMax = 5;
  if (graphMin > 0) graphMin = 0;

  minutes.forEach((minute, index) => {
    let hasPushedAriaLabel = false;
    const normalizedX = index / (minutes.length - 1);
    const phase = getSunPhaseFromElevation(minute.sun.elevation);

    if (currentPhase != null) {
      currentPhase.normalizedWidth = normalizedX - currentPhase.normalizedX;
    }

    if (currentPhase == null || currentPhase.type !== phase) {
      currentPhase = { type: phase, normalizedX: normalizedX, normalizedWidth: 0 };
      phases.push(currentPhase);

      ariaLabels.push(createAriaLabel({ minute, isFirstRender, index, translate }));
      hasPushedAriaLabel = true;
    }

    const normalizedElevation = (minute.sun.elevation - graphMin) / (graphMax - graphMin);
    normalizedLineCoordinate.push([normalizedX, normalizedElevation]);
    verticalLines.push({ normalizedX, type: 'hidden' });

    if (index % 24 === 0 && index !== 0 && hasPushedAriaLabel === false) {
      ariaLabels.push(createAriaLabel({ minute, isFirstRender, index, translate }));
    }

    if (index === minutes.length - 1 && hasPushedAriaLabel === false) {
      ariaLabels.push(createAriaLabel({ minute, isFirstRender, index, translate }));
    }
  });

  const normalizedZeroY = (0 - graphMin) / (graphMax - graphMin);

  const horizontalLines: TGraphGridNewHorizontalLine[] = [{ normalizedY: normalizedZeroY }];

  return {
    horizontalLines,
    verticalLines,
    normalizedLineCoordinate,
    phases,
    normalizedZeroY,
    ariaLabels
  };
}
function createAriaLabel({
  minute,
  isFirstRender,
  index,
  translate
}: {
  minute: IElevationMinute;
  isFirstRender: boolean;
  index: number;
  translate: ITranslateFunction;
}) {
  const timeLabel = createRelativeTimeLabel({
    type: 'relative-date-with-time-short',
    time: minute.time,
    isFirstRender,
    transform: 'sentence-case',
    translate
  });

  let sunPhaseLabel: string;
  if (minute.twilight != null) {
    sunPhaseLabel = sentenceCase(minute.twilight);
  } else if (minute.sun.elevation > SUN_ELEVATION_IS_ABOVE_HORIZON) {
    sunPhaseLabel = translate('celestialEvents/sunPhase/daylight');
  } else {
    sunPhaseLabel = translate('celestialEvents/sunPhase/night');
  }

  const roundedDegree = Math.round(minute.sun.elevation);
  const belowHorizon = minute.sun.elevation < SUN_ELEVATION_IS_ABOVE_HORIZON;

  const degree = Math.abs(roundedDegree);

  let elevationLabel: string;
  if (belowHorizon === true) {
    elevationLabel = translate('sunCard/underHorizon', { degree }).toLowerCase();
  } else {
    elevationLabel = translate('sunCard/overHorizon', { degree }).toLowerCase();
  }

  const label = translate('sunCard/sunGraph/ariaLabel', { timeLabel, elevationLabel, sunPhaseLabel });

  return { label, index };
}
