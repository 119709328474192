import { Icon } from '@nrk/yr-icons';
import { useState } from 'react';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { sentenceCase } from '../../lib/string';
import { IElevationMinute, ISun } from '../../model/celestialEvents';
import { Card, CardHeader } from '../Card/Card';
import { RelativeTime } from '../RelativeTime/RelativeTime';
import { SunGraph } from '../SunGraph/SunGraph';
import { Text } from '../Text/Text';
import {
  createSunEvents,
  createSunTexts,
  getHoverMinute,
  getSunPhaseDescription,
  getSunPhaseFromElevation,
  graphText
} from './helpers/data';

import './SunCard.scss';
import { SunCard_InfoPanel } from './SunCard__InfoPanel';

// -0.215 is when the sun is "actually" up
export const SUN_ELEVATION_IS_ABOVE_HORIZON = -0.215;

export interface IProps {
  currentLocationName: string;
  sun: ISun;
  elevationMinutes: IElevationMinute[];
}

export function SunCard(props: IProps) {
  const { currentLocationName, sun, elevationMinutes } = props;
  const currentHoverIndex = sun.nowIndex;
  const [hoverIndex, setHoverIndex] = useState(currentHoverIndex);

  const translate = useTranslate();

  function handleHover({ index }: { index: number }) {
    setHoverIndex(index);
  }

  function handleCancelHover() {
    setHoverIndex(currentHoverIndex);
  }

  const sunEvents = createSunEvents({ sunEvents: sun.events, translate, type: 'sun' });

  const sunTexts = createSunTexts({
    sunData: sun,
    translate: translate
  });

  const sunPhaseDescription = getSunPhaseFromElevation(elevationMinutes[hoverIndex].sun.elevation);

  return (
    <div
      className="sun-card"
      data-sun-phase={sunPhaseDescription}
      // Force dark mode for the card all sunphases except daylight
      data-theme={sunPhaseDescription === 'daylight' ? 'light' : 'dark'}
    >
      <Card className="sun-card__card" testId="sun-card">
        <CardHeader
          title={translate('sunCard/title')}
          subtitle={
            <RelativeTime
              type="relative-date-with-time-short"
              time={getHoverMinute(elevationMinutes, hoverIndex)}
              transform="sentence-case"
            />
          }
          infoButton={{
            type: 'button',
            renderInfoPanel: (openedById: string, onClose: () => void) => {
              return (
                <SunCard_InfoPanel
                  openedById={openedById}
                  onClose={() => {
                    onClose();
                  }}
                />
              );
            }
          }}
        />
        <div className="sun-card__card-content">
          <div className="sun-card__sun-graph-container">
            <Text as="div" size="5" className="sun-card__elevation-text">
              <span>
                {elevationMinutes[hoverIndex].twilight && sentenceCase(elevationMinutes[hoverIndex].twilight)}{' '}
                {getSunPhaseDescription(elevationMinutes[hoverIndex].sun.elevation, translate)}
              </span>
              <span>{graphText({ hoverIndex, minutes: elevationMinutes, translate })}</span>
            </Text>
            <SunGraph
              currentLocationName={currentLocationName}
              minutes={elevationMinutes}
              hoverIndex={hoverIndex}
              onHover={handleHover}
              onHoverCancel={handleCancelHover}
            />
          </div>
          <div className="sun-card__card-details">
            <ul className="sun-card__card-sun-events-list">
              {sunEvents.map((sunEvent, index) => (
                <Text as="li" size="4" key={index}>
                  <Icon className="sun-card__icon" id={sunEvent.iconId} size={2} />
                  <span className="sun-card__card-sun-events-text">{sunEvent.label}</span>
                </Text>
              ))}
            </ul>
            <ul className="sun-card__card-sun-details-list">
              {sunTexts &&
                sunTexts.map((sunText, index) => (
                  <li key={index}>
                    <Text size="5" className="sun-card__card-details-text">
                      {sunText}
                    </Text>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </Card>
    </div>
  );
}
