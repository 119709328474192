import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IElevationMinute } from '../../model/celestialEvents';
import { GraphGridNew } from '../GraphGridNew/GraphGridNew';
import { GraphLine } from '../GraphLine/GraphLine';
import { GraphShellNew } from '../GraphShellNew/GraphShellNew';
import { createSunGraphData } from './helpers/data';
import './SunGraph.scss';
import { SunGraph__Phases } from './SunGraph__Phases';

interface IProps {
  currentLocationName: string;
  minutes: IElevationMinute[];
  hoverIndex: number;
  onHover: (parameters: { index: number }) => void;
  onHoverCancel: () => void;
}

export function SunGraph(props: IProps) {
  const { currentLocationName, minutes, hoverIndex, onHover, onHoverCancel } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const translate = useTranslate();
  const { isFirstRender } = useAppState();

  const {
    horizontalLines,
    verticalLines,
    normalizedLineCoordinate,
    phases,
    normalizedZeroY,
    ariaLabels
  } = createSunGraphData({
    isFirstRender,
    minutes,
    translate
  });

  useEffect(() => {
    const closestIndex = ariaLabels.reduce(
      (previousIndex, currentItem) =>
        Math.abs(currentItem.index - hoverIndex) < Math.abs(previousIndex - hoverIndex)
          ? currentItem.index
          : previousIndex,
      0
    );

    const index = ariaLabels.findIndex(ariaLabel => {
      return ariaLabel.index === closestIndex;
    });

    setActiveIndex(index);
  }, [ariaLabels, hoverIndex]);

  const labels: string[] = ariaLabels.map(aria => {
    return aria.label;
  });

  return (
    <div className="sun-graph">
      <GraphShellNew
        testId="sun-graph"
        ariaLabel={translate('sunCard/sunGraph/label', { locationName: currentLocationName })}
        xAxisAriaLabels={{
          labels,
          activeIndex,
          onChange: event => onHover({ index: ariaLabels[event.index].index })
        }}
        rows={[
          {
            content: (
              <GraphGridNew
                height={85}
                horizontalLines={horizontalLines}
                verticalLines={verticalLines}
                hoverIndex={hoverIndex}
                hoverType={'tick'}
                onHover={onHover}
                onHoverCancel={onHoverCancel}
                hideHoverTickLine={true}
                draggable={true}
                hoverImage={{
                  className: 'sun-graph__sun-icon',
                  normalizedPositions: [
                    {
                      normalizedX: normalizedLineCoordinate[hoverIndex][0],
                      normalizedY: normalizedLineCoordinate[hoverIndex][1]
                    }
                  ],
                  href: '/assets/images/animated/sun.svg',
                  width: 54,
                  height: 54,
                  transform: 'translate(-27px, -23px)'
                }}
              >
                <SunGraph__Phases
                  normalizedLineCoordinate={normalizedLineCoordinate}
                  normalizedZeroY={normalizedZeroY}
                  phases={phases}
                />
                <GraphLine
                  className="sun-graph__line"
                  normalizedCoordinates={normalizedLineCoordinate}
                  strokeWidth={1}
                />
              </GraphGridNew>
            )
          }
        ]}
      />
    </div>
  );
}
