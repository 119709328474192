import { TIconId } from '@nrk/yr-icons';
import yrTime from '@nrk/yr-time';
import {
  createHoursAndMinutesLabelFromSeconds,
  createMinutesAndSecondLabelFromSeconds,
  createTimeLabel
} from '../../../lib/helpers/time';
import { IElevationMinute, ISolarEclipse, ISun, ISunEvent } from '../../../model/celestialEvents';
import { ITranslateFunction } from '../../../model/translate';
import { SUN_ELEVATION_IS_ABOVE_HORIZON } from '../SunCard';
import { Translate } from '../../Translate/Translate';

export const SUN_PHASE = [
  { value: -18, type: 'night' },
  { value: -12, type: 'astronomical twilight' },
  { value: -6, type: 'nautical twilight' },
  { value: -0.215, type: 'civil twilight' },
  { value: 90, type: 'daylight' }
];

interface IEventObject {
  sunrise: string[];
  sunset: string[];
  'polar-night-until': string[];
  'midnight-sun-until': string[];
}

interface ISunEvents {
  label: React.ReactNode;
  iconId: TIconId;
  sortByTime?: string;
}

export function createSunTexts({ sunData, translate }: { sunData: ISun; translate: ITranslateFunction }) {
  const sunTextsArray = [];

  const lenghtOfDay =
    sunData.lengthOfDay != null ? createLengthOfDayLabel({ seconds: sunData.lengthOfDay, translate }) : undefined;

  const differenceFromYesterday =
    sunData.differenceFromYesterday != null
      ? createDifferenceFromYesterdayLabel({
          seconds: sunData.differenceFromYesterday,
          translate
        })
      : undefined;

  const differenceFromSolstice = createDifferenceFromSolsticeLabel({
    seconds: sunData.differenceFromSolstice.difference,
    solstice: sunData.differenceFromSolstice.type,
    translate
  });

  const sunEventMax = sunData.events.find(event => event.type === 'max');
  const sunHighestPoint =
    sunEventMax !== null ? createSunHighestPointLabel({ sunEvent: sunEventMax, translate }) : undefined;

  const solarEclipse =
    sunData.nextSolarEclipse !== undefined ? getSolarEclipseText(sunData.nextSolarEclipse, translate) : undefined;

  if (sunData.events.some(data => data.type === 'polar-night-until')) {
    return [solarEclipse];
  }

  if (sunData.events.some(data => data.type === 'midnight-sun-until')) {
    return [sunHighestPoint, solarEclipse];
  }

  if (lenghtOfDay != null) {
    sunTextsArray.push(lenghtOfDay);
  }

  if (differenceFromYesterday != null) {
    sunTextsArray.push(differenceFromYesterday);
  }

  sunTextsArray.push(differenceFromSolstice);

  if (sunHighestPoint != null) {
    sunTextsArray.push(sunHighestPoint);
  }

  sunTextsArray.push(solarEclipse);

  return sunTextsArray;
}

export function createSunEvents({
  sunEvents,
  translate,
  type
}: {
  sunEvents: ISunEvent[];
  translate: ITranslateFunction;
  type: 'celestial' | 'sun';
}): ISunEvents[] {
  const eventObject: IEventObject = {
    sunrise: [],
    sunset: [],
    'polar-night-until': [],
    'midnight-sun-until': []
  };

  for (const sunEvent of sunEvents) {
    if (sunEvent.type === 'max' || sunEvent.type === 'min') {
      continue;
    }
    eventObject[sunEvent.type].push(sunEvent.time);
  }

  // If there is polar night we only want to show that.
  if (eventObject['polar-night-until'].length > 0) {
    return [
      {
        label: (
          <Translate
            id="celestialEvents/polarNightUntil"
            values={{
              timeLabel: (
                <time dateTime={eventObject['polar-night-until'][0]}>
                  {createTimeLabel({ type: 'date-short', time: eventObject['polar-night-until'][0], translate })}
                </time>
              )
            }}
          />
        ),
        iconId: 'icon-polar-night',
        sortByTime: eventObject['polar-night-until'][0]
      }
    ];
  }

  // If there is midnight sun we only want to show how long it will be midnight.
  if (eventObject['midnight-sun-until'].length > 0) {
    return [
      {
        label: (
          <Translate
            id="celestialEvents/midnightSunUntil"
            values={{
              timeLabel: (
                <time dateTime={eventObject['midnight-sun-until'][0]}>
                  {createTimeLabel({ type: 'date-short', time: eventObject['midnight-sun-until'][0], translate })}
                </time>
              )
            }}
          />
        ),
        iconId: 'icon-sun-filled',
        sortByTime: eventObject['midnight-sun-until'][0]
      }
    ];
  }

  // Format the label based on if there are one or two sunrises
  const sunEventArray: ISunEvents[] = [];
  if (eventObject.sunrise.length > 0) {
    const label =
      eventObject.sunrise.length === 1 ? (
        <Translate
          id="celestialEvents/sunrise"
          values={{
            timeLabel:
              type === 'celestial' ? (
                <time dateTime={yrTime.create(eventObject.sunrise[0]).format('HH:mm')}>
                  {yrTime.create(eventObject.sunrise[0]).format('HH:mm')}
                </time>
              ) : (
                <time dateTime={yrTime.create(eventObject.sunrise[0]).format('HH:mm')}>
                  {createTimeLabel({ type: 'hour-with-minutes', time: eventObject.sunrise[0], translate })}
                </time>
              )
          }}
        />
      ) : (
        <Translate
          id="celestialEvents/twoSunrises"
          values={{
            timeLabel:
              type === 'celestial' ? (
                <time dateTime={yrTime.create(eventObject.sunrise[0]).format('HH:mm')}>
                  {yrTime.create(eventObject.sunrise[0]).format('HH:mm')}
                </time>
              ) : (
                <time dateTime={yrTime.create(eventObject.sunrise[0]).format('HH:mm')}>
                  {createTimeLabel({ type: 'hour-with-minutes', time: eventObject.sunrise[0], translate })}
                </time>
              ),
            time: (
              <time dateTime={yrTime.create(eventObject.sunrise[1]).format('HH:mm')}>
                {yrTime.create(eventObject.sunrise[1]).format('HH:mm')}
              </time>
            )
          }}
        />
      );

    sunEventArray.push({ label, iconId: 'icon-sunrise', sortByTime: eventObject.sunrise[0] });
  }

  // Format the string based on if there are one or two sunsets
  if (eventObject.sunset.length > 0) {
    const label =
      eventObject.sunset.length === 1 ? (
        <Translate
          id="celestialEvents/sunset"
          values={{
            timeLabel:
              type === 'celestial' ? (
                <time dateTime={yrTime.create(eventObject.sunset[0]).format('HH:mm')}>
                  {yrTime.create(eventObject.sunset[0]).format('HH:mm')}
                </time>
              ) : (
                <time dateTime={yrTime.create(eventObject.sunset[0]).format('HH:mm')}>
                  {createTimeLabel({ type: 'hour-with-minutes', time: eventObject.sunset[0], translate })}
                </time>
              )
          }}
        />
      ) : (
        <Translate
          id="celestialEvents/twoSunsets"
          values={{
            timeLabel:
              type === 'celestial' ? (
                <time dateTime={yrTime.create(eventObject.sunset[0]).format('HH:mm')}>
                  {yrTime.create(eventObject.sunset[0]).format('HH:mm')}
                </time>
              ) : (
                <time dateTime={yrTime.create(eventObject.sunset[0]).format('HH:mm')}>
                  {createTimeLabel({ type: 'hour-with-minutes', time: eventObject.sunset[0], translate })}
                </time>
              ),
            time: (
              <time dateTime={yrTime.create(eventObject.sunset[1]).format('HH:mm')}>
                {yrTime.create(eventObject.sunset[1]).format('HH:mm')}
              </time>
            )
          }}
        />
      );

    sunEventArray.push({ label, iconId: 'icon-sunset', sortByTime: eventObject.sunset[0] });
  }
  // Sort sunEventArray in ascending order. This means that the earliest event will come first in the array.
  // Doing this because we want to show the sun events in a list based on when they are happening.
  sunEventArray.sort((sunEventA, sunEventB) =>
    yrTime.create(sunEventA.sortByTime).diff(yrTime.create(sunEventB.sortByTime))
  );

  return sunEventArray;
}

export function getSolarEclipseText(solarEclipse: ISolarEclipse, translate: ITranslateFunction) {
  let solarEclipseTranslationKey;
  if (solarEclipse.type === 'annular') {
    solarEclipseTranslationKey = 'sunCard/annularSolarEclipse';
  } else if (solarEclipse.type === 'partial') {
    solarEclipseTranslationKey = 'sunCard/partialSolarEclipse';
  } else {
    solarEclipseTranslationKey = 'sunCard/totalSolarEclipse';
  }

  return (
    <Translate
      id={solarEclipseTranslationKey}
      values={{
        timeLabel: (
          <time dateTime={solarEclipse.time}>
            {createTimeLabel({ type: 'date-with-year-short', time: solarEclipse.time, translate })}
          </time>
        )
      }}
    />
  );
}

export function getSunPhaseFromElevation(elevation: number) {
  for (let i = 0; i < SUN_PHASE.length; i++) {
    if (elevation <= SUN_PHASE[i].value) {
      return SUN_PHASE[i].type;
    }
  }

  throw new Error('Could not get sun phase');
}

export function getSunPhaseDescription(elevation: number, translate: ITranslateFunction): string | undefined {
  return translate(`celestialEvents/sunPhase/${getSunPhaseFromElevation(elevation)}`);
}

export function getHoverMinute(minutes: IElevationMinute[], hoverIndex: number) {
  const time = minutes[hoverIndex].time;
  return time;
}

export function graphText({
  hoverIndex,
  minutes,
  translate
}: {
  hoverIndex: number;
  minutes: IElevationMinute[];
  translate: ITranslateFunction;
}) {
  const roundedDegree = Math.round(minutes[hoverIndex].sun.elevation);
  const belowHorizon = minutes[hoverIndex].sun.elevation < SUN_ELEVATION_IS_ABOVE_HORIZON;

  const degree = Math.abs(roundedDegree);

  if (belowHorizon === true) {
    return translate('sunCard/underHorizon', { degree });
  }

  return translate('sunCard/overHorizon', { degree });
}

export function createLengthOfDayLabel({ seconds, translate }: { seconds: number; translate: ITranslateFunction }) {
  const timeLabel = createHoursAndMinutesLabelFromSeconds({ seconds, translate });
  return (
    <Translate
      id="sunCard/lengthOfDay"
      values={{ timeLabel: <time dateTime={dateTimeLabelFromSeconds(seconds)}>{timeLabel}</time> }}
    />
  );
}

// Creates a timelabel that describes difference from yesterday with a timelabel
export function createDifferenceFromYesterdayLabel({
  seconds,
  translate
}: {
  seconds: number;
  translate: ITranslateFunction;
}) {
  const isNegative = seconds < 0;
  const secondsAbsoluteValue = Math.abs(seconds);
  const timeLabel = createMinutesAndSecondLabelFromSeconds({ seconds: secondsAbsoluteValue, translate });
  const timeLabelWithSign = isNegative === false ? `+${timeLabel}` : `-${timeLabel}`;

  return (
    <Translate
      id="sunCard/differenceFromYesterday"
      values={{ timeLabel: <time dateTime={dateTimeLabelFromSeconds(secondsAbsoluteValue)}>{timeLabelWithSign}</time> }}
    />
  );
}

// Creates a timelabel that describes difference from winter solstice with a timelabel
export function createDifferenceFromSolsticeLabel({
  seconds,
  solstice,
  translate
}: {
  seconds: number;
  solstice: string;
  translate: ITranslateFunction;
}) {
  const isNegative = seconds < 0;
  const secondsAbsoluteValue = Math.abs(seconds);
  const timeLabel = createHoursAndMinutesLabelFromSeconds({ seconds: secondsAbsoluteValue, translate });
  const timeLabelWithSign = isNegative === false ? `+${timeLabel}` : `-${timeLabel}`;
  const solsticeLabel =
    solstice === 'winter-solstice'
      ? translate('terminology/solstice/winter')
      : translate('terminology/solstice/summer');

  return (
    <Translate
      id="sunCard/differenceFromSolstice"
      values={{
        solstice: solsticeLabel,
        timeLabel: <time dateTime={dateTimeLabelFromSeconds(secondsAbsoluteValue)}>{timeLabelWithSign}</time>
      }}
    />
  );
}

function createSunHighestPointLabel({ sunEvent, translate }: { sunEvent?: ISunEvent; translate: ITranslateFunction }) {
  if (sunEvent == null) {
    return;
  }
  return (
    <Translate
      id="sunCard/highestInTheSky"
      values={{
        timeLabel: (
          <time dateTime={yrTime.create(sunEvent.time).format('HH:mm')}>
            {createTimeLabel({ type: 'hour-with-minutes', time: sunEvent.time, translate })}
          </time>
        )
      }}
    />
  );
}

function dateTimeLabelFromSeconds(seconds?: number) {
  if (seconds == null) {
    return;
  }
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  hours = hours % 24;

  return `PT${hours > 0 ? hours + 'H' : ''}${minutes > 0 ? minutes + 'M' : ''}${seconds > 0 ? seconds + 'S' : ''}`;
}
